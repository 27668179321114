.slick-dots.slick-dots-bottom {
    bottom: -15px !important
}
.slick-dots.slick-dots-bottom li{
    width: 6px !important
}
.slick-dots li button {
    width: 6px !important;
    height: 6px !important;
    border-radius: 100% !important;
    background:#DDDDDD !important;
  }
.slick-dots li.slick-active button {
  width: 7px !important;
  height: 7px !important;
  border-radius: 100% !important;
  background: #000 !important;
}